<template>
  <div class="contents dashboard">
    <!-- <div class="contents dashboard" v-loading="loading"> -->
    <div class="title flexB">
      <h2>HOME</h2>
    </div>
    <div class="contWrap flex">
      <div class="cont">
        <h4><span class="material-icons">account_circle</span> Total Users</h4>
        <h3>
          {{ totalUser }}
        </h3>
      </div>

      <div class="cont">
        <h4>
          <span class="material-icons">account_circle</span> Monthly New Users
        </h4>
        <h3>
          {{ thisMonthJoinUser }}
        </h3>
      </div>
      <div class="cont">
        <h4>
          <span class="material-icons">account_circle</span> Daily New Users
        </h4>
        <h3>
          {{ dayJoinUser }}
        </h3>
      </div>
      <div class="cont">
        <h4>
          <span class="material-icons">account_circle</span> Deactivated Users
        </h4>
        <h3>
          {{ withdrawUser }}
        </h3>
      </div>
    </div>
    <div class="contList">
      <h3>
        Top 20
        <div>
          <span>As of {{ moment().format("YYYY.MM.DD hh:mm") }}</span>
          <button
            class="basic"
            @click="getLastestWordList"
            :class="{ loading: inactive == true }"
          >
            Refresh <img src="@/assets/images/refresh.svg" alt="Refresh" />
          </button>
        </div>
      </h3>
      <ul class="cont">
        <li v-for="(item, index) in popularWords" :key="index">
          <!-- <span>lorem Ipsum</span> -->
          <h4>
            Term<span>{{ item._id }}</span>
          </h4>
          <p>
            <span>Total : </span><span class="count">{{ item.count }}</span>
          </p>
          <!-- <p v-for="(data, i) in item.word_group" :key="i" v-show="data.email != ''">
            <span>검색자 : </span>
            <span>
              {{ data.email }}
            </span>
            <span>{{ data.count }}회</span>
          </p> -->
          <!-- <router-link to="/">
          </router-link> -->
        </li>
      </ul>
      <!-- <div class="cont" v-for="(data, i) in postList" :key="i">
        <router-link :to="`surveys/detail?id=${data._id}`">
          <span
            v-if="
              moment(data.endAt).add(1, 'd').format('YYYY.MM.DD') >
              moment().format('YYYY.MM.DD')
            "
          >
            {{ moment(data.startAt).format("YYYY.MM.DD") }} ~
            {{ moment(data.endAt).format("YYYY.MM.DD") }}
          </span>
          <span v-else> 설문마감 </span>
          <h4>{{ data.title }}</h4>
          <p>
            <span>설문금액</span>
            {{ numberFormat(data.totalAmount) }}
            <span>원</span>
          </p>
        </router-link>
      </div> -->
    </div>
    <!-- <div class="pagination">
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div> -->
  </div>
</template>

<script>
import moment from "moment";
//import { fetchPostList } from "@/api/post";
//import { format } from "@/mixins/format";
import { fetchDashboardList, fetchDashboardWordList } from "@/api/admin";
export default {
  //mixins: [format],
  data() {
    return {
      moment: moment,
      loading: false,
      // total: 0,
      // currentPage: 1,
      // dayJoinUser: 0,
      // liveFunding: 0,
      // totalFunding: 0,
      totalUser: 0,
      thisMonthJoinUser: 0,
      dayJoinUser: 0,
      withdrawUser: 0,
      popularWords: [],
      // postList: [],
      inactive: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 0);
  },
  mounted() {
    this.getDashboardList();
    this.getLastestWordList();
  },
  methods: {
    // handleCurrentChange(val) {
    //   this.currentPage = val;
    //   this.getDashboardList();
    // },
    getDashboardList() {
      this.loading = true;
      fetchDashboardList().then((res) => {
        if (res.data.status == 200) {
          this.totalUser = res.data.totalUser;
          this.thisMonthJoinUser = res.data.thisMonthJoinUser;
          this.dayJoinUser = res.data.dayJoinUser;
          this.withdrawUser = res.data.withdrawUser;
        } else {
          return alert(res.data.message);
        }
        this.loading = false;
      });
    },
    getLastestWordList() {
      //this.loading = true;
      this.inactive = true;
      let params = {
        limit: 20,
      };
      fetchDashboardWordList(params).then((res) => {
        if (res.data.status == 200) {
          // console.log("fetchDashboardWordList", res.data.data)
          this.popularWords = res.data.data;
        } else {
          return alert(res.data.message);
        }
        //this.loading = false;
        this.inactive = false;
      });
    },
    // getPostList() {
    //   let params = {
    //     searchWord: this.searchWord,
    //     inputMode: this.inputMode,
    //     category: this.category,
    //     sort: "latest",
    //   };
    //   fetchPostList(params).then((res) => {
    //     if (res.data.status == 200) {
    //       this.postList = res.data.postList;
    //       this.total = res.data.total;
    //       this.currentPage = res.data.page;
    //     } else {
    //       let message = res.data.message;
    //       alert(message);
    //     }
    //   });
    // },
  },
};
</script>
